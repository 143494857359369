<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="'Alterar Data do Próximo Contacto da Lead'"
      :modal="true"
      :closable="false"
    >
      <div class="p-d-flex p-jc-center">
        <v-date-picker
          v-model="currentNextContact"
          mode="dateTime"
          :min-date="new Date()"
          is24hr
          :masks="{
            input: 'YYYY-MM-DD',
          }"
        >
        </v-date-picker>
      </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setNewContact"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
        <Button
        :label="'Limpar Data'"
        icon="pi pi-eraser"
        @click="currentNextContact = null"
      />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import { getOnlyDate, getOnlyTime } from "../../helpers/helpers";
export default {
  name: "CrmChangeNexContact",
  components: {},
  props: [
    "showToast",
    "show",
    "proposalId",
    "proposalSubject",
    "selected",
    "nextContact",
  ],
  watch: {
    show() {
      if (this.show === true) {
        this.$validator.resume();
        if (this.selected) {
          this.currentNextContact = null;
        } else {

          this.currentNextContact = new Date (this.nextContact);
        }

      } else {
        this.$validator.pause();
      }
    },
  },
  data() {
    return {
      showLoader: false,
      currentNextContact: null,
    };
  },
  async created() {},
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    setNewContact() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let params = {
          next_contact: this.currentNextContact
            ? `${getOnlyDate(this.currentNextContact)} ${getOnlyTime(
                this.currentNextContact
              )}`
            : null,
        };
        if (this.selected != null) {
          return this.setNewContactMultipleProposals(params);
        }
        this.showLoader = true;
        return crmService.update(this.proposalId, params).then((response) => {
          this.showLoader = false;
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao alterar a prioridade da lead",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("changedNextContact", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Prioridade da lead alterada com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("changedNextContact", {
            status: "ok",
            result: response,
          });
        });
      });
    },
    async setNewContactMultipleProposals(params) {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      for (const proposal of this.selected) {
        await crmService.update(proposal.id, params).then((response) => {
          if (!response) {
            return (noErrors = false);
          }
          res = response;
        });
      }

      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar a prioridade da lead",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedNextContact", {
          status: "error",
        });
      }
      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Prioridade da lead alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedNextContact", {
        status: "ok",
        result: res,
      });
    },
    contactsListLabel(item) {
      let label = `${item.name}`;
      if (item.email != "") {
        label += ` | ${item.email}`;
      }
      if (item.telephone != "") {
        label += ` | ${item.telephone.replace(" ", "")}`;
      }
      if (item.type != "") {
        label += ` | ${item.type}`;
      }
      return label;
    },
  },
};
</script>
<style>
.p-dropdown-panel {
  width: 50%;
}
</style>
