<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="'Alterar Vendedor da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="crmProposalsChangeSeller"
        class="p-col-12"
        @submit.prevent="setSeller"
        style="min-height: 15vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <p v-if="!selected">
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
        <p
          v-else
          v-for="proposalSelected in selected"
          :key="proposalSelected.id"
        >
          <b>Lead {{ proposalSelected.id }} | {{ proposalSelected.subject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 40vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="proposalSeller"
                :options="sellerList"
                :filter="true"
                :optionLabel="getSearchLabel"
                :optionValue="'id'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('proposalSeller') },
                  'form-control',
                ]"
              >
                <template #option="slotProps">
                  {{ slotProps.option.name }}
                </template>
              </Dropdown>
              <label for="proposalSeller">Selecionar o Vendedor da Lead</label>
            </span>
            <small
              v-if="errors.has('proposalSeller')"
              class="p-error"
              role="alert"
            >
              Vendedor é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setSeller"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import prospectService from "../../services/prospect.service";
import crmService from "../../services/crm.service";
import unidecode from "unidecode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CrmChangeSeller",
  components: {
    Loading,
  },
  props: [
    "showToast",
    "show",
    "sellerId",
    "proposalId",
    "proposalSubject",
    "selected",
  ],
  watch: {
    show() {
      if (this.show === true) {
        this.$validator.resume();
        this.showLoader = false;
        this.current =
          !this.selected || this.selected == undefined ? this.sellerId : null;
      } else {
        this.$validator.pause();
      }
    },
  },
  data() {
    return {
      current: !this.selected ? this.sellerId : null,
      sellerList: [],
      showLoader: false,
    };
  },
  async created() {
    this.sellerList = await prospectService.getSellers();
  },
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    setSeller() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.selected != null) {
          return this.setSellerMultipleProposals();
        }
        this.showLoader = true;
        return crmService
          .update(this.proposalId, { seller_id: parseInt(this.current) })
          .then((response) => {
            this.showLoader = false;
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o vendedor da proposta",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedProposalSeller", {
                status: "error",
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Vendedor da lead alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedProposalSeller", {
              status: "ok",
              result: response,
            });
          });
      });
    },
    async setSellerMultipleProposals() {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      for (const proposal of this.selected) {
        await crmService
          .update(proposal.id, { seller_id: parseInt(this.current) })
          .then((response) => {
            if (!response) {
              return (noErrors = false);
            }
            res = response;
          });
      }
      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar o vendedor da lead",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedProposalSeller", {
          status: "error",
        });
      }

      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Vendedor da lead alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedProposalSeller", {
        status: "ok",
        result: res,
      });
    },
    getSearchLabel(info) {
      return `${unidecode(info.name)}`;
    },
  },
};
</script>
