<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="'Alterar Cliente da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="CRMChangeClientForm"
        class="p-col-12"
        @submit.prevent="setClient"
        style="min-height: 50vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <p v-if="!selected">
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
        <p
          v-else
          v-for="proposalSelected in selected"
          :key="proposalSelected.id"
        >
          <b>Lead {{ proposalSelected.id }} | {{ proposalSelected.subject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Dropdown
                name="clientType"
                :options="clientTypeList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="currentClientType"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('clientType') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="clientType">Tipo de Cliente da Lead</label>
            </span>
            <small v-if="errors.has('clientType')" class="p-error" role="alert">
              Tipo de Cliente é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-2"
            v-if="currentClientType > 1"
          >
            <span class="p-float-label">
              <Dropdown
                v-model="currentClientContactId"
                name="contactId"
                :options="contactsList"
                optionValue="id"
                dataKey="reference"
                :optionLabel="contactsListLabel"
                class="form-control"
                :filter="true"
              />
              <label for="contactId">Contacto do Parceiro</label>
            </span>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-2"
            v-if="currentClientType == 1"
          >
            <span class="p-float-label">
              <inputText
                name="clientName"
                v-model="currentClientName"
                class="form-control"
              />
              <label for="clientName">Potencial Cliente</label>
            </span>
          </div>

          <div
            class="p-field p-col-12 p-md-12 p-mt-2"
            v-if="currentClientType == 1"
          >
            <span class="p-float-label">
              <inputText
                name="clientPerson"
                v-model="currentClientPerson"
                class="form-control"
              />
              <label for="clientPerson">Potencial Cliente Representante</label>
            </span>
          </div>

          <div
            class="p-field p-col-12 p-md-12 p-mt-2"
            v-if="currentClientType == 1"
          >
            <span class="p-float-label">
              <inputText
                name="clientTelephone"
                v-model="currentClientTelephone"
                class="form-control"
              />
              <label for="clientTelephone">Potencial Cliente Telefone</label>
            </span>
          </div>

          <div
            class="p-field p-col-12 p-md-12 p-mt-2"
            v-if="currentClientType == 1"
          >
            <span class="p-float-label">
              <inputText
                name="clientEmail"
                v-model="currentClientEmail"
                class="form-control"
              />
              <label for="clientEmail">Potencial Cliente Email</label>
            </span>
          </div>

          <div
            class="p-field p-col-12 p-md-12 p-mt-2"
            v-if="currentClientType == 1"
          >
            <span class="p-float-label">
              <Textarea
                rows="5"
                :autoResize="true"
                name="clientComments"
                v-model="currentClientComments"
                v-bind:class="['p-inputtextarea', 'p-inputtext', 'p-component']"
              />
              <label for="clientComments">Potencial Cliente Comentários</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setClient"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import externalManagerService from "../../services/externalManager.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CrmChangeClient",
  components: {
    Loading,
  },
  props: [
    "showToast",
    "show",
    "proposalId",
    "proposalSubject",
    "selected",
    "clientTypeId",
    "clientName",
    "clientPerson",
    "clientTelephone",
    "clientEmail",
    "clientComments",
    "clientContactId",
  ],
  watch: {
    show() {
      if (this.show === true) {
        this.$validator.resume();
        this.currentClientType = null;
        this.currentClientName = null;
        this.currentClientPerson = null;
        this.currentClientTelephone = null;
        this.currentClientEmail = null;
        this.currentClientComments = null;
        this.currentClientContactId = null;
      } else {
        this.$validator.pause();
      }
    },
    clientTypeId() {
      this.showLoader = false;
      this.currentClientType = !this.selected ? this.clientTypeId : null;
      this.currentClientName = this.clientName;
      this.currentClientPerson = this.clientPerson;
      this.currentClientTelephone = this.clientTelephone;
      this.currentClientEmail = this.clientEmail;
      this.currentClientComments = this.clientComments;
      this.currentClientContactId = this.clientContactId;
    },
  },
  data() {
    return {
      clientTypeList: [],
      contactsList: [],
      showLoader: false,
      currentClientType: !this.selected ? this.clientTypeId : null,
      currentClientName: null,
      currentClientPerson: null,
      currentClientTelephone: null,
      currentClientEmail: null,
      currentClientComments: null,
      currentClientContactId: null,
    };
  },
  async created() {
    this.clientTypeList = await crmService.getClientTypeList();
    this.contactsList = await externalManagerService.getAll();
  },
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    setClient() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let params = {
          client_type: this.currentClientType,
        };

        if (this.currentClientType == 1) {
          params.potential_client_name = this.currentClientName;
          params.potential_client_person = this.currentClientPerson;
          params.potential_client_telephone = this.currentClientTelephone;
          params.potential_client_email = this.currentClientEmail;
          params.potential_client_comments = this.currentClientComments;
        } else if (this.currentClientType > 1) {
          params.contact_id = this.currentClientContactId;
        }

        if (this.selected != null) {
          return this.setClientMultipleProposals(params);
        }
        this.showLoader = true;
        return crmService.update(this.proposalId, params).then((response) => {
          this.showLoader = false;
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao alterar a prioridade da lead",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("changedClientType", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Prioridade da lead alterada com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("changedClientType", {
            status: "ok",
            result: response,
          });
        });
      });
    },
    async setClientMultipleProposals(params) {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      for (const proposal of this.selected) {
        await crmService.update(proposal.id, params).then((response) => {
          if (!response) {
            return (noErrors = false);
          }
          res = response;
        });
      }

      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar a prioridade da lead",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedClientType", {
          status: "error",
        });
      }
      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Prioridade da lead alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedClientType", {
        status: "ok",
        result: res,
      });
    },
    contactsListLabel(item) {
      let label = `${item.name}`;
      if (item.email != "") {
        label += ` | ${item.email}`;
      }
      if (item.telephone != "") {
        label += ` | ${item.telephone.replace(" ", "")}`;
      }
      if (item.type != "") {
        label += ` | ${item.type}`;
      }
      return label;
    },
  },
};
</script>
<style>
.p-dropdown-panel {
  width: 50%;
}
</style>
