<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogMergeLeads" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
      :header="'Merge Leads'"
      :modal="true"
      :closable="false"
    >
      <Loading
        :active.sync="showLoader"
        :isFullPage="false"
        :zIndex="9000"
      ></Loading>

      <DataTable
        :value="selected"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :selection.sync="master"
        :sortOrder="1"
        sortField="id"
      >
        <template #header>
          <div class="table-header">
            Selecione a Lead que irá receber as restantes leads da listagem em
            baixo.
          </div>
        </template>
        <Column selectionMode="single" :headerStyle="{ width: '3em' }"></Column>
        <Column field="id" header="Id"></Column>
        <Column field="subject" header="Assunto"></Column>
        <Column field="status" header="Estado">
          <template #body="slotProps">
            <span v-if="slotProps.data.status">
              {{ slotProps.data.proposal_status.name }}
            </span>
          </template>
        </Column>
        <Column field="priority" header="Prioridade">
          <template #body="slotProps">
            <span v-if="slotProps.data.proposal_priority">
              {{ slotProps.data.proposal_priority.name }}
            </span>
          </template>
        </Column>
        <Column field="closing_probability" header="Prob. Fechar">
          <template #body="slotProps">
            {{ slotProps.data.closing_probability }}%
          </template>
        </Column>
      </DataTable>

      <template #footer>
        <Button
          v-if="master"
          :label="'Merge'"
          icon="pi pi-check"
          class="p-button-success"
          @click="merge"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "MergeLeads",
  components: {
    Loading,
  },
  props: ["showToast", "show", "selected"],
  data() {
    return {
      showLoader: false,
      master: null,
    };
  },

  methods: {
    cancel() {
      this.master = null;
      return this.$emit("cancel");
    },
    merge() {
      let leadsToImportIds = this.selected.filter((lead) => {
        if (lead.id != this.master.id) {
          return lead.id;
        }
      });
      let leadsToImport = leadsToImportIds.map((ticket) => ticket.id);
      let message = `Tem a certeza que pretende importar as leads ${leadsToImport.join(
        ", "
      )} para a Lead W4M_${this.master.id}?`;
      message += `\nEste processo é irreversível.`;

      this.$confirm.require({
        key: "dialogMergeLeads",
        header: "Merge Leads",
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.importLeads(leadsToImport);
        },
        reject: () => {},
      });
    },
    importLeads(leadsToImport) {
      this.showLoader = true;
      return crmService
        .importLeads(this.master.id, {
          proposals_import: leadsToImport,
        })
        .then((response) => {
          this.master = null;
          this.showLoader = false;
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao fazer merge das leads",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("mergedLeads", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Merge das Leads Efetuado com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("mergedLeads", {
            status: "ok",
            destinationLeadId: response.id,
          });
        });
    },
  },
};
</script>
